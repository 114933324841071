<template>
  <div class="warranty-info">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách thông tin bảo hành'">
          <template v-slot:toolbar>
            <div class="row">
              <button
                v-bind:style="btnCreate"
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm"
                @click="showModalAddWarrantyInfo"
                v-if="checkPermission('PRODUCT_INSERT')"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              </button>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <b-row class="mb-5">
              <b-col md="2">
                <b-form-input
                  v-on:input="onFilter"
                  v-model="searchName"
                  type="text"
                  placeholder="Tìm kiếm theo tiêu đề"
                  size="sm"
                ></b-form-input>
              </b-col>
            </b-row>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="listItem"
              :fields="fields"
              sort-by="count"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>

              <template v-slot:cell(count)="row">
                <div style="text-align: center">
                  <span v-text="row.item.count" class="text-right"></span>
                </div>
              </template>
              <template v-slot:cell(createdAt)="row">
                <p class="text-center">{{ row.item.createdAt }}</p>
                <p class="text-center">{{ row.item.createdBy }}</p>
              </template>

              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="editItem(row.item)" v-else>
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon-eye"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-show="checkPermission('CUSTOMER_CARE_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số :
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1"
                  last-class="page-item-last btn btn-icon btn-sm m-1"
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
            <b-modal
              ref="add-warranty-info-modal"
              hide-footer
              title="Thêm mới thông tin bảo hành"
              size="xl"
            >
              <v-form ref="form" lazy-validation>
                <div class="w-50">
                  <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Tiêu đề:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      type="name"
                      v-model="name"
                      required
                      placeholder="Nhập tiêu đề"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.name.required"
                      id="input-2-live-feedback"
                      >Yêu cầu nhập tiêu đề</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div>
                  <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Nội dung:</span>
                    </template>
                    <b-form-textarea
                      id="textarea-description"
                      v-model="content"
                      placeholder="Nhập nội dung"
                      rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('PRODUCT_INSERT')"
                  @click="createWarrantyInfo"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddWarrantyInfo"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="update-warranty-info-modal"
              hide-footer
              :title="
                checkPermission('PRODUCT_UPDATE')
                  ? 'Cập nhật thông tin bảo hành'
                  : 'Chi tiết thông tin bảo hành'
              "
              size="xl"
            >
              <v-form ref="form" lazy-validation>
                <div class="w-50">
                  <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Tiêu đề:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      type="name"
                      v-model="name"
                      required
                      placeholder="Nhập tiêu đề"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.name.required"
                      id="input-2-live-feedback"
                      >Yêu cầu nhập tiêu đề</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div>
                  <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Nội dung:</span>
                    </template>
                    <b-form-textarea
                      id="textarea-description"
                      v-model="content"
                      placeholder="Nhập nội dung"
                      rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('PRODUCT_INSERT')"
                  @click="updateWarrantyInfo"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalUpdateWarrantyInfo"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { IMPORT_STOCK } from '../../../utils/constants';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
import localData from '../../../utils/saveDataToLocal';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';

export default {
  mixins: [validationMixin],
  data() {
    return {
      onLoading: false,
      disabledDates: {},
      btnCreate: {
        fontWeight: '600!important',
      },
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      type: IMPORT_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'name',
          label: 'Tiêu đề',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'content',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '50%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        { key: 'actions', label: '' },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      limit: 10,
      name: '',
      isNew: false,
      warrantyInfoId: null,
      searchName: '',
      content: '',
      listItem: [],
    };
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    onEndChange: function () {
      this.onFilter();
    },
    onStartChange: function () {
      this.onFilter();
    },
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const params = {
        page: this.page,
        limit: 10,
        name: this.searchName ? this.searchName.trim() : '',
      };

      ApiService.query('warrantyInfo', { params })
        .then((response) => {
          this.listItem = [];
          const dataResponse = response.data.data.data;
          this.totalPages = response.data.data.total_page;
          this.totalItems = response.data.data.total_row;
          const result = [];
          dataResponse.forEach((item, index) => {
            const stock = {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              name: item.name,
              content: item.content,
              createdBy: item.createdBy,
              createdAt: moment(String(item.createdAt)).format(
                'DD/MM/YYYY HH:mm:ss'
              ),
            };
            result.push(stock);
            this.listItem = [...new Set(result)];
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.warrantyInfoId = item.id;
      this.getWarrantyInfoById();
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa thông tin bảo hành!',
        text: 'Bạn có chắc muốn xóa thông tin bảo hành này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      const id = item.id;
      ApiService.setHeader();
      ApiService.delete(`warrantyInfo/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-warranty-info',
      });
      this.debounceSearch();
    },
    debounceSearch: decounce(function () {
      this.fetchData();
    }, TIME_TRIGGER),
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    showModalAddWarrantyInfo() {
      this.name = '';
      this.content = '';
      this.$refs['add-warranty-info-modal'].show();
    },
    hideModalAddWarrantyInfo() {
      this.$refs['add-warranty-info-modal'].hide();
    },
    createWarrantyInfo: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const data = {
        title: this.name,
        content: this.content,
      };
      ApiService.post('warrantyInfo', data)
        .then(({ data }) => {
          this.isNew = true;
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalAddWarrantyInfo();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.isNew = true;
          this.makeToastFaile(response.$error);
        });
    },
    showModalUpdateWarrantyInfo() {
      this.$refs['update-warranty-info-modal'].show();
    },
    hideModalUpdateWarrantyInfo() {
      this.$refs['update-warranty-info-modal'].hide();
    },
    getWarrantyInfoById: function () {
      ApiService.get(`warrantyInfo/${this.warrantyInfoId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.name = data.data.title;
            this.content = data.data.content;
            this.showModalUpdateWarrantyInfo();
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    updateWarrantyInfo: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let data = {
        id: this.warrantyInfoId,
        title: this.name,
        content: this.content,
      };
      ApiService.put('warrantyInfo', data)
        .then(({ data }) => {
          this.isNew = true;
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalUpdateWarrantyInfo();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.isNew = true;
          this.makeToastFaile(response.$error);
        });
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('PRODUCT_VIEW')) {
        count++;
      }
      if (localData.checkPermission('PRODUCT_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách thông tin bảo hành' },
    ]);
  },
  created() {
    let requireCode = this.$route.query.code;
    if (requireCode !== undefined) {
      this.searchId = requireCode;
    }
    this.fetchData();
  },
  components: {
    KTCodePreview,
    VclTable,
  },
};
</script>

<style lang="scss">
.warranty-info {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  input:focus {
    outline: none !important;
    border: 1px solid #92c5f9;
  }

  select {
    height: 2.5em;
  }

  .example {
    border: 1px solid #ddd;
    margin-bottom: 2em;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  /* h5 {
    font-size: 100%;
    padding: 0;
  } */

  .form-group {
    margin-bottom: 1em;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }
  .productCode:hover {
    text-decoration: underline;
  }
}
</style>
